@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

* {
  margin: 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
}

input {
  border: solid 2px #efefef;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  transition-duration: 0.5s;
}
input:focus {
  border: solid 2px #992123;
}

body {
  font-family: "Ubuntu", sans-serif;
  text-decoration: none;
}

button {
  border: solid 2px #992123;
  color: #992123;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition-duration: 0.5s;
}

button:hover {
  background-color: #992123;
  color: #ffffff;
}

.App {
  width: 1100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0px auto;
}

.App > img {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  object-fit: cover;
}

.App > .search_form {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.App > .search_form > input {
  flex: 1;
}
.App > .search_form > button {
  margin-left: 5px;
}

.tweets_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.tweets {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: solid 2px #efefef;
  border-radius: 5px;
  margin: 0px 10px;
  margin-top: 20px;
}
.tweets_primary {
  border: solid 2px #992123;
}
.tweet {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #992123;
  padding: 10px;
  color: black;
}
.tweet > .tweet_head {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tweet > .tweet_head > img {
  border-radius: 100%;
  object-fit: cover;
  margin-right: 5px;
}
.tweet > .tweet_head > div > h4 {
  color: black;
}
.tweet > .tweet_head > div > span {
  color: #ababab;
  font-weight: 300;
}
.tweet > .tweet_content {
  margin: 10px 0px;
  font-weight: 400;
  height: 60px;
  display: flex;
  align-items: center;
}
.tweet > .tweet_bottom {
  width: 100%;
  cursor: pointer;
}
.tweet > .tweet_bottom > span {
  display: block;
  border-bottom: solid 1px #efefef;
  padding-bottom: 5px;
  margin-bottom: 5px;
  color: #cccccc;
  font-weight: 300;
}
.tweet > .tweet_bottom > label {
  color: #aaaaaa;
  font-weight: 300;
  margin-right: 20px;
}

.tweet > .tweet_bottom > label > span {
  font-weight: 500;
  color: #992123;
  margin-right: 5px;
}
